<template>
  <b-overlay :show="loading">
    <form-filter-export @onExport="exportPenerimaanBarang"></form-filter-export>
    <b-card title="Daftar Penerimaan Barang">
      <b-row v-if="allowCreate()">
        <b-col class="my-1">
          <b-button
          v-b-modal.modal-tambah
          variant="primary"
          v-if="allowCreate() && myGudang"
          @click="add()"
          >
          <!-- @click.prevent="$router.push('/penerimaan-barang/add')" -->
            <feather-icon icon="PlusIcon" class="mr-50" />
            Penerimaan Barang
          </b-button>
        </b-col>
      </b-row>
      <b-modal
            v-if="allowCreate() || allowUpdate()"
            v-model="showModal"
            id="modal-"
            ok-title="Tutup"
            ok-variant="secondary"
            ok-only
            centered
            title="Form "
            size="lg"
      no-close-on-backdrop
          >
            <validation-observer ref="form">
              <div class="d-flex">
                <feather-icon icon="PackageIcon" size="19" />
                <h6 class="ml-50 mb-2">Penerimaan Barang</h6>
              </div>
              <b-form>
                <b-row>
              <b-col sm="12" md="3">
                <b-form-group
                  label="Pilih Nomor Invoice"
                  label-for="v-jenis"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="jenis"
                    rules=""
                  >
                  <div>
                    <b-form-radio-group
                      v-model="form.jenis"
                      :options="options"
                      class="inline-spacing"
                      value-field="value"
                      text-field="text"
                      disabled-field="disabled"
                    />
                  </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="3">
                <b-form-group label="No. Invoice" label-for="v-invoice">
                  <validation-provider
                    #default="{ errors }"
                    name="no_invoice"
                    rules=""
                  >
                  <div v-if="form.jenis == 0">
                    <b-form-input
                      id="v-invoice"
                      v-model="form.no_invoice"
                      placeholder="Isi nomor invoice"
                      :state="errors.length > 0 ? false : null"
                    />
                  </div>
                  <div v-if="form.jenis == 1">
                    <b-form-input
                      id="v-invoice"
                      v-model="form.no_invoice"
                      placeholder=""
                      :state="errors.length > 0 ? false : null"
                      disabled
                    />
                  </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group v-if="this.user.level.id == 5">
                  <label for="gudang">Gudang</label>
                  <b-form-select
                    disabled
                    v-model="form.gudang_id"
                    :options="dataGudang"
                    label="Gudang"
                  />
                </b-form-group>
                <b-form-group v-if="this.user.level.id != 5">
                  <label for="gudang">Gudang</label>
                  <b-form-select
                    v-model="form.gudang_id"
                    :options="dataGudang"
                    label="Gudang"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group label="Tanggal Penerimaan" label-for="v-tanggal">
                  <validation-provider
                    #default="{ errors }"
                    name="tanggal_penerimaan"
                    rules="required"
                  >
                    <b-form-input
                      id="v-tanggal"
                      v-model="form.tanggal_penerimaan"
                      type="date"
                      placeholder="Tanggal"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group label="Tanggal Invoice" label-for="v-tanggal">
                  <validation-provider
                    #default="{ errors }"
                    name="tanggal_invoice"
                    rules="required"
                  >
                    <b-form-input
                      id="v-tanggal"
                      v-model="form.tanggal_invoice"
                      type="date"
                      placeholder="Tanggal Invoice"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group >
                  <label for="supplier">Supplier</label>
                  <!-- <b-form-select
                    v-model="form.supplier_id"
                    :options="datasupplier"
                    label="supplier"
                  /> -->
                  <v-select
          v-model="form.supplier_id"
          label="text"
          :options="datasupplier"
        />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Keterangan (optional)"
                  label-for="v-keterangan"
                >
                  <b-form-textarea
                    v-model="form.keterangan"
                    id="v-keterangan"
                    placeholder="Keterangan"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
                  <!-- submit and reset -->
                  <b-col cols="12">
                    <b-button
                      type="submit"
                      @click.prevent="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      Simpan
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                    >
                      Reset
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">
          <b-form-group
            :label="$t('Sort')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6" class="my-1">
          <b-form-group
            :label="$t('Filter')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
          <b-input-group>
                      <b-form-input v-model="filter" placeholder="Tekan enter untuk cari"
                        @keydown.enter.prevent="getPenerimaanBarangs()"></b-form-input>
                      <b-input-group-append>
                        <b-button variant="outline-primary" @click="getPenerimaanBarangs()">
                          Cari
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            v-if="this.isAdminGudang"
            small
            striped
            hover
            responsive
            :per-page="perPage"
            :items="penerimaanBarangs"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
          >
            <template #cell(no)="row">
              <strong class="text-center">
                {{ row.index + 1 }}
              </strong>
            </template>
            
            <template #cell(selesai)="data">
              <b-badge :variant="ket_selesai[1][data.value]">
                {{ ket_selesai[0][data.value] }}
              </b-badge>
            </template>
            <template #cell(po)="{ item }">
              {{ item.po ? item.po.no : "po kosong" }}
            </template>
            
          <template #cell(tanggal_penerimaan)="{ item }">
            {{humanDate(item.tanggal_penerimaan)}}
          </template>
          
          <template #cell(tanggal_invoice)="{ item }">
            {{humanDate(item.tanggal_invoice)}}
          </template>
            <template #cell(actions)="{ item }">
              <section class="flex item-center">
                <b-button
                  v-b-tooltip.hover.right="'Detail'"
                  size="sm"
                  @click="$router.push(`/penerimaan-barang/view/${item.id}`)"
                  class="mr-1"
                  variant="outline-primary"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
                <b-button
                  v-if="
                    (allowUpdate() && item.selesai == 0) ||
                    (allowUpdate() && !isAdminGudang)
                  "
                  v-b-tooltip.hover.right="'Ubah'"
                  size="sm"
                  @click="$router.push(`/penerimaan-barang/edit/${item.id}`)"
                  class="mr-1"
                  variant="outline-info"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <!-- v-if="penerimaanBarangs.selesai = 1" -->
                <b-button
                  v-if="
                    (allowDelete() && item.selesai == 0) ||
                    (allowUpdate() && !isAdminGudang)
                  "
                  v-b-tooltip.hover.right="'Hapus'"
                  size="sm"
                  @click="remove(item)"
                  class="mr-1"
                  variant="outline-danger"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </section>
            </template>

            <template #row-details="row">
              <b-card>
                <ul>
                  <li v-for="(value, key) in row.item" :key="key">
                    {{ key }}: {{ value }}
                  </li>
                </ul>
              </b-card>
            </template>
          </b-table>
          <b-table
            v-if="!isAdminGudang"
            small
            striped
            hover
            responsive
            :per-page="perPage"
            :items="penerimaanBarangs"
            :fields="fields2"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
          >
            <template #cell(no)="row">
              <strong class="text-center">
                {{ row.index + 1 }}
              </strong>
            </template>
            <template #cell(selesai)="data">
              <b-badge :variant="ket_selesai[1][data.value]">
                {{ ket_selesai[0][data.value] }}
              </b-badge>
            </template>
            <template #cell(po)="{ item }">
              {{ item.po ? item.po.no : "po kosong" }}
            </template>

            <template #cell(actions)="row">
              <section class="flex item-center">
                <b-button
                  v-b-tooltip.hover.right="'Ubah'"
                  size="sm"
                  @click="$router.push(`/penerimaan-barang/view/${row.item.id}`)"
                  class="mr-1"
                  variant="outline-primary"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
                <b-button
                  v-if="
                    (allowUpdate() && row.item.selesai == 0) ||
                    (allowUpdate() && !isAdminGudang)
                  "
                  v-b-tooltip.hover.right="'Ubah'"
                  size="sm"
                  @click="$router.push(`/penerimaan-barang/edit/${row.item.id}`)"
                  class="mr-1"
                  variant="outline-info"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <!-- v-if="penerimaanBarangs.selesai = 1" -->
                <b-button
                  v-if="
                    (allowDelete() && row.item.selesai == 0) ||
                    (allowUpdate() && !isAdminGudang)
                  "
                  v-b-tooltip.hover.right="'Hapus'"
                  size="sm"
                  @click="remove(row.item)"
                  class="mr-1"
                  variant="outline-danger"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </section>
            </template>

            <template #row-details="row">
              <b-card>
                <ul>
                  <li v-for="(value, key) in row.item" :key="key">
                    {{ key }}: {{ value }}
                  </li>
                </ul>
              </b-card>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
import vSelect from 'vue-select'
import { extend } from "vee-validate";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormFilterExport from '@core/components/form-filter-export/FormFilterExport.vue'
import {
  BOverlay,
  BModal,
  BTable,
  VBTooltip,
  BAvatar,
  BCard,
  BBadge,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BFormTextarea,
} from "bootstrap-vue";
import mixin from './mixin'
export default {
  mixins: [mixin],
  components: {
    vSelect,
  BForm,
  FormFilterExport,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    ValidationObserver,
    ValidationProvider,
    BCard,
    VBTooltip,
    ToastificationContent,
    BModal,
    BTable,
    BOverlay,
    BAvatar,
    BFormSelect,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data: () => ({
    required,
      password,
      email,
      confirmed,
    params: {
      gudang_id: null,
    },
    loading: false,
    id: null,
    datasupplier:null,
    form: {
        // form tab content 1
        // gudang_id: 1, // sementara
        gudang_id: null,
        no_invoice: null,
        jenis: 0,
        tanggal_invoice: null,
        tanggal_penerimaan: null,
        keterangan: null,
      },
      options: [
        { text: 'Otomatis', value: '1'},
        { text: 'Manual', value: '0'},
      ],
      dataGudang:[],
    perPage: 10,
    pageOptions: [10, 20, 50],
    totalRows: 1,
    currentPage: 1,
    sortBy: "",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    penerimaanBarangs: [],
    directive: {
    "b-tooltip": VBTooltip,
  },
    ket_selesai: [
      {
        0: "Belum ditempatkan",
        1: "Sudah",
      },
      {
        0: "light-danger",
        1: "light-success",
      },
    ],
    fields: [
      {
        key: "no",
        label: "No",
      },
      {
        key: "gudang.nama_gudang",
        label: "Gudang",
        sortable: true,
      },
      { key: "no_invoice", label: "No. Invoice", sortable: true },
      {
        key: "tanggal_penerimaan",
        label: "Tanggal Penerimaan",
        sortable: true,
      },
      { key: "tanggal_invoice", label: "Tanggal Invoice", sortable: true },
      { key: "selesai", label: "Status" },
      { key: "actions", label: "Aksi" },
    ],
    showModal: false,
    fields2: [
      {
        key: "no",
        label: "No",
      },
      {
        key: "gudang.nama_gudang",
        label: "Gudang",
      },
      { key: "no_invoice", label: "No. Invoice", sortable: true },
      {
        key: "tanggal_penerimaan",
        label: "Tanggal Penerimaan",
        sortable: true,
      },
      { key: "tanggal_invoice", label: "Tanggal Invoice", sortable: true },
      { key: "selesai", label: "Status" },
      { key: "actions", label: "Aksi" },
    ],
  }),
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    "form.jenis"(j){
      if(j == 1){
        this.form.no_invoice = this.getNewNoInvoice();
      } else {
        this.form.no_invoice = null;
      }
    },
    perPage(value) {
      if (value) {
        this.getPenerimaanBarangs();
      }
    },
   // filter(keyword) {
    //  if (keyword.length > 2 || keyword.length == 0) {
    //    this.getPenerimaanBarangs();
     // }
    //},
    currentPage(val) {
      if (val) {
        this.getPenerimaanBarangs();
        // this.items
      }
    },
  },
  methods: {
    async exportPenerimaanBarang({tgl_awal, tgl_akhir, type_export}) {
      if(!tgl_awal || !tgl_akhir) {
        this.displayError({
          message: 'Harap isi tanggal awal dan akhir!'
        })
        return false
      }

      const params = {
        tgl_awal,
        tgl_akhir,
        type_export
      }

      try {
        this.loading = true
        const response = await this.doExport(params)
        this.loading = false
        await window.open(response)
        setTimeout(async () => {
            // delete selected file
            const arrFile = response.split('/')
            const filename = arrFile[ arrFile.length - 1 ]

            await this.clearExportedFile(filename)
          }, 1000)
      }
      catch(e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    submit() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          if (this.id) {
            this.form.id = this.id;
          }
          if (!this.form.keterangan) {
            this.form.keterangan = "-";
          }
          if (this.form.supplier_id) {
            this.form.supplier_id = this.form.supplier_id.value;
          }
          const payload = this.form;
          try {
            this.label = "Loading...";
            const ajuan = await this.$store.dispatch("penerimaan-barang/save", [payload]);
            this.label = "Submit";
            if (this.id) {
              this.getData();
              this.displaySuccess({
                message: "Ajuan berhasil disimpan",
              });
            } else {
              this.displaySuccess({
                message: "Ajuan berhasil disimpan",
              });
              this.$router.push(`/penerimaan-barang/add/${ajuan.id}`);
            }
          } catch (e) {
            console.error(e);
            this.displayError(e);
            return false;
          }
        }
      });
    },
    getDataGudang() {
      this.$store
        .dispatch("gudang/getData", {
          jenis: 1,
        })
        .then(() => {
          let ter = JSON.parse(JSON.stringify(this.$store.state.gudang.datas));
          const gudangs = [];

          if (this.isAdminGudang) {
            ter.map((gudang) => {
              const itsMyGudang = gudang.id == this.myGudang.id;
              if (itsMyGudang) {
                this.form.gudang_id = this.myGudang.id;
                gudangs.push({
                  value: gudang.id,
                  text: gudang.nama_gudang,
                });
              }
            });
          } else {
            ter.map((item) => {
              gudangs.push({
                value: item.id,
                text: item.nama_gudang,
              });
            });
          }
          this.dataGudang = gudangs;
        });
    },
    async getNewNoInvoice() {
      const noInvoice = await this.$store.dispatch(
        "penerimaan-barang/getNewNoInvoice"
      );
      this.form.no_invoice = noInvoice.message.format;
    },
    add() {
      this.resetForm();
      this.showModal = true;
    this.form.tanggal_penerimaan = this.getCurrentDate()
    this.form.tanggal_invoice = this.getCurrentDate()
    },
    resetForm() {
      this.id = null;
      this.form.id = null;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Penerimaan Barang ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        // const allowed = result.value;
        if (result.value) {
          // this.loading = true;
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("penerimaan-barang/save", [item])
            .then(() => {
              // this.loading = false;
              this.displaySuccess({
                message: "Data berhasil dihapus",
              });
              this.getPenerimaanBarangs();
            })
            .catch((e) => {
              // this.loading = false;
              this.displayError(e);
              return false;
            });
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getPenerimaanBarangs() {
      this.loading = true;
      this.loaded = false;
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        gudang_id: this.myGudang.id,
        search: this.filter != null ? this.filter : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
      }
      this.$store
        .dispatch("penerimaan-barang/getData", payload)
        .then((response) => {
      
          let items = JSON.parse(JSON.stringify(this.$store.state['penerimaan-barang'].datas));
          let items_total = this.$store.state['penerimaan-barang'].totals;
      this.penerimaanBarangs = items;
      this.totalRows = items_total;
          this.loaded = true;
          this.loading = false;
        })
        .catch((e) => {
          this.loaded = true;
          this.loading = false;
          console.error(e);
          this.displayError(e);

          return false;
        });
    },
    async getsupplier() {
      let supplier = await this.$store.dispatch("supplier/getData");
      let items = JSON.parse(JSON.stringify(this.$store.state.supplier.datas));
         let newsupplier = [];
         items.map((item) => {
        newsupplier.push({
          text: item.nohp ? item.instansi + ' - ' + item.nohp : item.instansi,
          value: item.id,
        });
      });

      this.datasupplier = newsupplier;
    },
  },
  async created() {
    this.loading = true
    this.form.tanggal_penerimaan = this.getCurrentDate()
    this.form.tanggal_invoice = this.getCurrentDate()
    await this.getPenerimaanBarangs();
    await this.getsupplier();
    await this.getDataGudang();
    this.loading = false
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
